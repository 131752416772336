.main-block {
    background: url("../../../static/main_background.png") no-repeat;
    background-size: cover;
    height: 600px; // если экран меньше, то финиш (снизу пустой белый блок)

    .white {
        color: white !important;
    }

    padding-left: 15%;
    padding-top: 10%;


    .big-name {
        //styleName: главная заголовок;
        font-family: "Arial Black", serif;
        font-size: 152px;
        font-weight: 400;
        line-height: 217.38px;
        letter-spacing: 0.035em;
        text-align: left;

    }
}