.content {
    max-width: 1200px;
    width: 100%;
}

.modal-window {
    path {
        stroke: #8F8F8F;
    }
}

.app {
    //display: flex;
    //flex-direction: column;
    //align-items: center;

    //.content {
    //    max-width: 1200px;
    //    width: 100%;
    //}

    .typical-header {
        background: #D3D3D3;
        position: sticky;
        top: 0;
        z-index: 999;

        .content {
            .header-links {
                a {
                    &:hover {
                        color: #8F8F8F;
                    }
                }
            }
        }
    }

    .typical-footer {
        background: #262626;
    }

    .svg-icon {
        path {
            stroke: #EBB350;
        }

        &.header {
            path {
                stroke: #000000;
            }
        }
    }

    #about, #services, #contacts {
        scroll-margin-top: 120px;
    }
}