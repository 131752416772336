.why-block {
    background: #262626;

    .white {
        color: white !important;
    }

    .pluses {
        background: #FFC762;
        width: 100%;
        height: 150px;
        border-radius: 10px;
        padding: 20px;
    }

    .H4 {
        font-size: 18px !important;
    }

    .H2 {
        text-align: center;
    }

    .rooms {
        background: linear-gradient(2.9deg, #FFC762 2.22%, #FFDFA5 86.21%);
        width: 180px;
        height: 180px;
        border-radius: 15px;
        padding: 8px;
    }

}