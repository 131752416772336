.services-block {
    background: #EAEAEA;
    padding-bottom: 100px;
    .service {
        border-radius: 15px;
        box-shadow: 0 4px 18px rgba(171, 183, 210, 0.7);
        background: white;
        padding: 20px;
        width: 230px;
        height: 260px;

        .button {
            background: #FFD484;
            box-shadow: none;
            color: black !important;

            &:hover {
                background: #EDBD69;
            }

            &:active {
                background: #FFDFA5;
            }
        }
    }
}