@import "@mryaros/mryaros-design-system/src/lib/css/flex.scss";
@import "@mryaros/mryaros-design-system/src/lib/css/text_mixin.scss";
@import "@mryaros/mryaros-design-system/src/lib/css/colors.scss";
@import "@mryaros/mryaros-design-system/src/lib/css/margin.scss";
@import "@mryaros/mryaros-design-system/src/lib/css/things.scss";
@import "@mryaros/mryaros-design-system/src/lib/css/padding.scss";
@import "@mryaros/mryaros-design-system/src/lib/css/width.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* Скрываем scrollbar для IE, Edge и Firefox */
  -ms-overflow-style: none;  /* IE и Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Скрываем scrollbar для Chrome, Safari и Opera */
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    scroll-behavior: smooth;
}

.margin-b-0 {
    margin-bottom: 0;
}
